import { Button, FormRow, FormSet, H1, Input, Section } from '@dnb/eufemia';
import {
  OrganizationDto,
  organizationInputSchema,
} from '@portals/shared/admin/OrganizationDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { z } from 'zod';

import { saveOrganization } from '@/api/organizations';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';

type OrganizationForm = z.infer<typeof organizationInputSchema>;

export default function OrganizationForm(): JSX.Element {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const { data: organization, isValidating: organizationLoading } =
    useSWR<OrganizationDto>(
      organizationId && `/organizations/${organizationId}`,
    );

  const {
    controller: { setValues },
    controller,
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(organizationInputSchema, {});

  useEffect(() => {
    if (controller.values.ciamProdPartyId === '') {
      controller.setValue('ciamProdPartyId', null);
    }
    if (controller.values.ciamUatPartyId === '') {
      controller.setValue('ciamUatPartyId', null);
    }
  }, [
    controller,
    controller.values.ciamProdPartyId,
    controller.values.ciamUatPartyId,
  ]);

  useEffect(() => {
    if (organization) {
      setValues(organization);
    }
  }, [organization, setValues]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        await saveOrganization(data);
        navigate('..');
      },
      [navigate],
    ),
  );

  const title = organization ? 'Edit organization' : 'Add organization';
  const isLoading = organizationLoading;

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/organizations">Organizations</BackButton>
      <Container centered size="small">
        {submitting && <LoadingModal />}

        <H1 bottom={false} top="large">
          {title}
        </H1>

        <FormSet on_submit={onSubmit} vertical>
          <Section spacing="x-large" style_type="white">
            <FormRow>
              <Input label="Name" stretch {...register.input('name')} />
            </FormRow>
            {!organizationId && (
              <FormRow top="medium">
                <Input label="Domain" stretch {...register.input('domain')} />
              </FormRow>
            )}
            <FormRow>
              <Input
                label="Ciam Uat Party Id"
                stretch
                top="medium"
                {...register.input('ciamUatPartyId')}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Ciam Prod Party Id"
                stretch
                top="medium"
                {...register.input('ciamProdPartyId')}
              />
            </FormRow>
          </Section>
          <Section spacing="medium" style_type="divider">
            <Button
              disabled={submitting}
              icon="save"
              icon_position="left"
              type="submit"
            >
              Save
            </Button>
          </Section>
        </FormSet>
      </Container>
    </>
  );
}
