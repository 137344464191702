import { Button, Dd, Dl, Dt, H1, H2, P, Section } from '@dnb/eufemia';
import { refresh as RefreshIcon } from '@dnb/eufemia/icons';
import type { OrganizationDetailsDto } from '@portals/shared/admin/OrganizationDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
  addMissingUsersToOrganization,
  deleteOrganization,
  deleteUserFromOrganization,
} from '@/api/organizations';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import DataTable, { Column } from '@/components/DataTable';
import DeleteDialog from '@/components/DeleteDialog';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface UserRow {
  id: string;
  name: string;
  email: string;
}

interface TeamRow {
  id: string;
  name: string;
  description: string | null;
  admins: string;
}

const USER_COLUMNS: Column<UserRow>[] = [
  { header: 'Name', attribute: 'name' },
  { header: 'Email', attribute: 'email' },
];

const TEAM_COLUMNS: Column<TeamRow>[] = [
  { header: 'Name', attribute: 'name' },
  { header: 'Description', attribute: 'description' },
  { header: 'Admins', attribute: 'admins' },
];

export default function OrganizationShow(): JSX.Element {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const {
    data,
    isValidating: detailsLoading,
    mutate,
  } = useSWR<OrganizationDetailsDto>(`/organizations/${organizationId}`);

  const isLoading = detailsLoading;

  const userRows = useMemo(
    () =>
      data?.users.map<UserRow>(({ id, firstName, lastName, email }) => ({
        id,
        name: `${firstName} ${lastName}`,
        email,
      })) ?? [],
    [data],
  );

  const teamRows = useMemo(
    () =>
      data?.teams.map<TeamRow>(({ id, name, description, members }) => ({
        id,
        name,
        description,
        admins: members
          .filter(({ isAdmin }) => isAdmin)
          .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
          .sort()
          .join(', '),
      })) ?? [],
    [data],
  );

  const onDeleteUser = useAsync(
    async ({ id }: UserRow) => {
      if (organizationId) {
        await deleteUserFromOrganization(id, organizationId);
        mutate((details) => {
          if (details) {
            return {
              ...details,
              users: details?.users.filter((user) => user.id !== id) ?? [],
            };
          }

          return;
        }, false);
      }
    },
    [mutate, organizationId],
  );

  const onDelete = useAsync(async () => {
    if (organizationId) {
      await deleteOrganization(organizationId);
      navigate('..', { replace: true });
    }
  }, [navigate, organizationId]);

  const onAddMissingUsersToOrganization = useAsync(async () => {
    if (organizationId) {
      await addMissingUsersToOrganization(organizationId);
      mutate();
    }
  }, [mutate, organizationId]);

  if (isLoading || !data) {
    return <LoadingPage />;
  }

  const isUpdating =
    onDeleteUser.waiting || onAddMissingUsersToOrganization.waiting;

  return (
    <>
      {isUpdating && <LoadingModal />}

      <BackButton to="/organizations">Organizations</BackButton>

      <Container centered size="small">
        <H1 bottom="x-large">{data.name}</H1>
        <Section spacing="x-large">
          <H2>Info</H2>
          <Dl top="large">
            <Dt>Name</Dt>
            <Dd>{data.name}</Dd>
            <Dt>Domain</Dt>
            <Dd>{data.domain}</Dd>
            <Dt>Created</Dt>
            <Dd>{humanDate(data.createdAt)}</Dd>
            <Dt>Ciam Uat party Id</Dt>
            <Dd>{data.ciamUatPartyId ?? '-'}</Dd>
            <Dt>Ciam Prod party Id</Dt>
            <Dd>{data.ciamProdPartyId ?? '-'}</Dd>
          </Dl>
        </Section>
      </Container>
      <Section spacing="x-large" style_type="divider">
        <H2 bottom="small" size="x-large" top={false}>
          Users
        </H2>
        <DataTable
          barContent={
            <div>
              <P>
                If there are missing users in the table below you can add them
                by clicking the <i>Syncronize users</i> button below.
              </P>
              <Button
                icon={RefreshIcon}
                icon_position="left"
                on_click={onAddMissingUsersToOrganization.execute}
                top="medium"
                variant="secondary"
              >
                Synchronize users
              </Button>
            </div>
          }
          columns={USER_COLUMNS}
          data={userRows}
          defaultSortKey="name"
          filterBy={['name', 'email']}
          onDelete={onDeleteUser.execute}
        />
      </Section>
      <Section spacing="x-large" style_type="divider">
        <H2 bottom="large" size="x-large" top={false}>
          Teams
        </H2>
        <DataTable
          columns={TEAM_COLUMNS}
          data={teamRows}
          defaultSortKey="name"
          filterBy={['name', 'description', 'admins']}
          onShow={({ id }) => navigate(`/teams/${id}`)}
        />
      </Section>

      <Section spacing="x-large" variant="warning">
        <H2 bottom="large" size="x-large" top={false}>
          Delete
        </H2>
        <P bottom="medium">This will permanently delete the organization.</P>
        <DeleteDialog
          loading={onDelete.waiting}
          onDelete={onDelete.execute}
          text="This will permanently delete the organization and the action cannot be undone."
          title="Are you sure you want to delete this organization?"
          triggerVariant="signal"
        />
      </Section>
    </>
  );
}
