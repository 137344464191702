import { object, string, z } from 'zod';

import { UUID_MATCHER } from '../common/matchers';
import { INVALID_UUID } from '../common/messages';
import type { TeamWithMembersDto } from './TeamDto';
import type { UserDto } from './UserDto';

export interface OrganizationDto {
  id: string;
  name: string;
  domain: string;
  createdAt: string;
  userCount: number;
  ciamProdPartyId: string | null;
  ciamUatPartyId: string | null;
}
export interface OrganizationDetailsDto extends OrganizationDto {
  users: UserDto[];
  teams: TeamWithMembersDto[];
}

export const organizationInputSchema = object({
  id: string().regex(UUID_MATCHER, INVALID_UUID).optional(),
  name: string().min(1),
  domain: string().min(1),
  ciamProdPartyId: string().regex(UUID_MATCHER, INVALID_UUID).nullable(),
  ciamUatPartyId: string().regex(UUID_MATCHER, INVALID_UUID).nullable(),
});

export type OrganizationInputDto = z.infer<typeof organizationInputSchema>;
