import { boolean, object, string, z } from 'zod';

import { ATMOST_N } from '../common/messages';
import type { ApiWithStatusAndScopesDto } from './ApiDto';
import type { ScopeDto } from './ScopeDto';

export enum Decision {
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  LEVEL1_APPROVED = 'LEVEL1_APPROVED',
  PRESUBMIT = 'PRESUBMIT',
}

export interface EntitlementRequestDto {
  id: string;
  appId: string;
  apiId: string;
  scopeIds: string[];
  decision: Decision;
  decisionComment?: string | null;
  decisionMadeAt?: string;
  useCase: string;
  createdAt: string;
  updatedAt: string;
  externalTicketComment?: string | null;
  externalTicketId?: string | null;
}

export interface EntitlementRequestWithScopeDto
  extends Pick<
    EntitlementRequestDto,
    | 'id'
    | 'decision'
    | 'apiId'
    | 'decisionComment'
    | 'decisionMadeAt'
    | 'useCase'
    | 'createdAt'
    | 'externalTicketComment'
    | 'externalTicketId'
  > {
  approvalStage?: string;
  apiName: string;
  apiDescription: string | null;
  scopes: ScopeDto[];
  serviceNowIdentifier: string | null;
}

export interface AppApiEntitlementDto {
  apiId: string;
  liveMode: boolean;
  id: string;
}

export type CurrentApiAndScopeEntitlementsDto = {
  api: ApiWithStatusAndScopesDto;
  entitlements: AppApiEntitlementDto[];
  scopeEntitlements: ScopeEntitlement[];
};

export type ScopeEntitlement = {
  scopeId: string;
  scopeName: string;
  scopeDescription: string;
  liveMode: boolean;
};

export const appApiEntitlementRequestSchema = object({
  apiId: string().min(1),
  liveMode: boolean().default(false),
  useCase: string().max(500, ATMOST_N(500)),
  scopeIds: string().array(),
  termsOfUseAccepted: boolean().optional(),
  status: z.enum([Decision.PENDING, Decision.PRESUBMIT]).optional(),
});

export const editTestModeScopesRequestSchema = object({
  selectedScopeIds: string().array(),
});

export const appApiEntitlementRequestInputSchema = z.array(
  appApiEntitlementRequestSchema,
);

export type AppApiEntitlementRequest = z.infer<
  typeof appApiEntitlementRequestSchema
>;

export type AppApiEntitlementInputRequest = z.infer<
  typeof appApiEntitlementRequestInputSchema
>;
